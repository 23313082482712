<template>
  <PageTemplate>
		<Grid>
			<GridContainer size="12">
				<Card size="large">
					<CardSection>
						<Grid>
							<GridContainer size="1"></GridContainer>
							<GridContainer size="5" align="middle">
								<Stack direction="column" space="3" align="left">
									<Heading size="1">Axiom Design System</Heading>
									<Paragraph>Ensuring a consistent look and feel, while balancing multiple products, is a demanding and expensive task. The Axiom Design System was created to address just that.</Paragraph>
									<Paragraph>Axiom is a collection of reusable components made with Vue.js, a light-weight theming layer using CSS and SCSS variables, and guidelines for best practices and coding implementation which can be used together to create amazing user experiences.</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="5">
								<Theme ar>
									<Images width="100%" image="theme-ds/home-ar.jpg" />
								</Theme>
								<Theme dl>
									<Images width="100%" image="theme-ds/home-dl.jpg" />
								</Theme>
								<Theme ed>
									<Images width="100%" image="theme-ds/home-ed.jpg" />
								</Theme>
								<Theme hv>
									<Images width="100%" image="theme-ds/home-hv.jpg" />
								</Theme>
								<Theme sd>
									<Images width="100%" image="theme-ds/home-sd.jpg" />
								</Theme>
								<Theme wm>
									<Images width="100%" image="theme-ds/home-wm.jpg" />
								</Theme>
								<Theme ds>
									<Images width="100%" image="theme-ds/home-ds.jpg" />
								</Theme>
							</GridContainer>
							<GridContainer size="1"></GridContainer>
						</Grid>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12 6@sm 4@md 3@lg">
				<Card fill>
					<CardSection>
						<Link url="component/button">
							<Stack space="2" align="top">
								<Stack>
									<Icon icon="view_agenda" size="large" background="secondary" color="light" />
								</Stack>
								<Stack direction="column" space="2" align="left">
									<Heading size="5">Button</Heading>
									<Paragraph micro>Buttons allow an application to communicate action and direct user intent.</Paragraph>
								</Stack>
							</Stack>
						</Link>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12 6@sm 4@md 3@lg">
				<Card fill>
					<CardSection>
						<Link url="component/card">
							<Stack space="2" align="top">
								<Stack>
									<Icon icon="recent_actors" size="large" background="secondary" color="light" />
								</Stack>
								<Stack direction="column" space="2" align="left">
									<Heading size="5">Card</Heading>
									<Paragraph micro>Cards are used to apply a container around a related grouping of information.</Paragraph>
								</Stack>
							</Stack>
						</Link>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12 6@sm 4@md 3@lg">
				<Card fill>
					<CardSection>
						<Link url="component/form">
							<Stack space="2" align="top">
								<Stack>
									<Icon icon="dns" size="large" background="secondary" color="light" />
								</Stack>
								<Stack direction="column" space="2" align="left">
									<Heading size="5">Form</Heading>
									<Paragraph micro>Forms manage the collection and submission of data from your user to the application.</Paragraph>
								</Stack>
							</Stack>
						</Link>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12 6@sm 4@md 3@lg">
				<Card fill>
					<CardSection>
						<Link url="component/grid">
							<Stack space="2" align="top">
								<Stack>
									<Icon icon="grid_view" size="large" background="secondary" color="light" />
								</Stack>
								<Stack direction="column" space="2" align="left">
									<Heading size="5">Grid</Heading>
									<Paragraph micro>A grid provides a structure of rows and columns for aligning content.</Paragraph>
								</Stack>
							</Stack>
						</Link>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12 6@sm 4@md 3@lg">
				<Card fill>
					<CardSection>
						<Link url="component/color">
							<Stack space="2" align="top">
								<Stack>
									<Icon icon="favorite_outline" size="large" background="secondary" color="light" />
								</Stack>
								<Stack direction="column" space="2" align="left">
									<Heading size="5">Icon</Heading>
									<Paragraph micro>Icons act as visual aids to help users complete tasks.</Paragraph>
								</Stack>
							</Stack>
						</Link>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12 6@sm 4@md 3@lg">
				<Card fill>
					<CardSection>
						<Link url="component/image">
							<Stack space="2" align="top">
								<Stack>
									<Icon icon="image" size="large" background="secondary" color="light" />
								</Stack>
								<Stack direction="column" space="2" align="left">
									<Heading size="5">Image</Heading>
									<Paragraph micro>An image is a graphic representation of something.</Paragraph>
								</Stack>
							</Stack>
						</Link>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12 6@sm 4@md 3@lg">
				<Card fill>
					<CardSection>
						<Link url="component/page">
							<Stack space="2" align="top">
								<Stack>
									<Icon icon="space_dashboard" size="large" background="secondary" color="light" />
								</Stack>
								<Stack direction="column" space="2" align="left">
									<Heading size="5">Page</Heading>
									<Paragraph micro>Page components provide a structure to house all other components.</Paragraph>
								</Stack>
							</Stack>
						</Link>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12 6@sm 4@md 3@lg">
				<Card fill>
					<CardSection>
						<Link url="component/typography">
							<Stack space="2" align="top">
								<Stack>
									<Icon icon="view_module" size="large" background="secondary" color="light" />
								</Stack>
								<Stack direction="column" space="2" align="left">
									<Heading size="5">Stack</Heading>
									<Paragraph micro>Stacks are used to lay out a row or column of components.</Paragraph>
								</Stack>
							</Stack>
						</Link>
					</CardSection>
				</Card>
			</GridContainer>
		</Grid>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/designsystem/template/Page'; 
export default {
  components: {
		PageTemplate
  },
  data() {
    return {
      theme: ''
		}
  },
  mounted() {
    let bodyElement = document.documentElement;
    bodyElement.setAttribute('theme', 'theme-ds');
		
		this.stageTheme(this.theme);
  },
}
</script>

<style lang="scss" scoped>

</style>
